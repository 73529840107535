@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'),
    local('.LucidaGrandeUI'), local('Ubuntu Light'), local('Segoe UI Light'), local('Roboto-Light'),
    local('DroidSans'), local('Tahoma');
}

@font-face {
  font-family: 'BentonSans Regular';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/BentonSans/regular/BentonSans.eot') format('truetype');
  src: url('../fonts/BentonSans/regular/BentonSans.eot#iefix') format('embedded-opentype'),
    url('../fonts/BentonSans/regular/BentonSans.woff') format('woff'),
    url('../fonts/BentonSans/regular/BentonSans.ttf') format('truetype'),
    url('../fonts/BentonSans/regular/BentonSans.woff2') format('woff2');
}

@font-face {
  font-family: 'BentonSans Bold';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/BentonSans/bold/BentonSans-Bold.eot') format('truetype');
  src: url('../fonts/BentonSans/bold/BentonSans-Bold.eot#iefix') format('embedded-opentype'),
    url('../fonts/BentonSans/bold/BentonSans-Bold.woff') format('woff'),
    url('../fonts/BentonSans/bold/BentonSans-Bold.ttf') format('truetype'),
    url('../fonts/BentonSans/bold/BentonSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'BentonSans Book';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/BentonSans/book/BentonSans-Book.eot') format('truetype');
  src: url('../fonts/BentonSans/book/BentonSans-Book.eot#iefix') format('embedded-opentype'),
    url('../fonts/BentonSans/book/BentonSans-Book.woff') format('woff'),
    url('../fonts/BentonSans/book/BentonSans-Book.ttf') format('truetype'),
    url('../fonts/BentonSans/book/BentonSans-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'BentonSans Medium';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/BentonSans/medium/BentonSans-Medium.eot') format('truetype');
  src: url('../fonts/BentonSans/medium/BentonSans-Medium.eot#iefix') format('embedded-opentype'),
    url('../fonts/BentonSans/medium/BentonSans-Medium.woff') format('woff'),
    url('../fonts/BentonSans/medium/BentonSans-Medium.ttf') format('truetype'),
    url('../fonts/BentonSans/medium/BentonSans-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'BentonSans light';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/BentonSans/light/BentonSans-Light.eot') format('truetype');
  src: url('../fonts/BentonSans/light/BentonSans-Light.eot#iefix') format('embedded-opentype'),
    url('../fonts/BentonSans/light/BentonSans-Light.woff') format('woff'),
    url('../fonts/BentonSans/light/BentonSans-Light.ttf') format('truetype'),
    url('../fonts/BentonSans/light/BentonSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'BentonSans Light SC';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/BentonSans/lightsc/BentonSans-LightSC.eot') format('truetype');
  src: url('../fonts/BentonSans/lightsc/BentonSans-LightSC.eot#iefix') format('embedded-opentype'),
    url('../fonts/BentonSans/lightsc/BentonSans-LightSC.woff') format('woff'),
    url('../fonts/BentonSans/lightsc/BentonSans-LightSC.ttf') format('truetype'),
    url('../fonts/BentonSans/lightsc/BentonSans-LightSC.woff2') format('woff2');
}
