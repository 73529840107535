.wizard-container {
  .spiro-card__body {
    overflow: auto;
  }
}

.import-table {
  border-right: 1px solid $mercury;
  border-left: 1px solid $mercury;
  width: 1038px;
  margin: 25px auto 0 auto;
  table-layout: fixed;
  border-collapse: collapse;
  background-color: $white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  border-radius: 4px;
  position: relative;

  .import-table-scroll {
    display: flex;
    justify-content: center;

    .loader-bouncing-dots {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  thead {
    display: table;
    width: 100%;
  }

  tbody {
    overflow: auto;
    display: block;
    width: 1038px;
    position: relative;
    max-height: 45vh;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 50px;
  }

  .import-table__tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid $mercury;
    height: 51px;
    overflow-x: auto;

    &--no-data {
      width: 1038px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      height: 50px;
      text-align: center;
    }
  }

  thead .import-table__tr {
    height: 53px;
  }

  .import-table__th {
    padding-top: 14px;
    padding-bottom: 9px;
    padding-left: 0px;
    vertical-align: top;
    width: 200px;

    &:last-child {
      padding-right: 28px;
      padding-left: 0;
    }

    &--capitalize {
      text-transform: capitalize;
    }

    .ordering-arrow {
      @extend .import-history-arrow-icon;
      top: 0;
      width: 7px;
      height: 5px;
      margin-left: 5px;
      display: inline-block;
      background-repeat: no-repeat;
      margin-bottom: 1px;
    }

    .ordering-arrow--rotate {
      transform: rotate(180deg);
    }
  }

  .import-table__td,
  .import-table__th {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .import-table__td {
    padding: 4px 8px 0 0px;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:last-child {
      padding-right: 26px;
      padding-left: 0;
    }

    &--download {
      font-weight: 500;

      span {
        outline: none;
        user-select: none;
      }
    }

    &--download-underline {
      text-decoration: underline;
    }

    &--scheduled {
      color: orange;

      span {
        outline: none;
        user-select: none;
        background: rgba(255, 165, 0, 0.1);
        padding: 5px 9px 4px 9px;
        border-radius: 4px;
      }
    }

    &--progress {
      color: $flamingo;

      span {
        outline: none;
        user-select: none;
        background: rgba(238, 99, 36, 0.1);
        padding: 5px 9px 4px 9px;
        border-radius: 4px;
      }
    }

    &--completed {
      color: $eastern-blue;

      span {
        outline: none;
        user-select: none;
        padding: 5px 9px 4px 9px;
        background: rgba(32, 170, 158, 0.1);
        border-radius: 4px;
      }
    }

    &--success {
      color: $eastern-blue;
      display: inline;
      padding: 5px 8px 3px 8px;
      background: rgba(32, 170, 158, 0.1);
      border-radius: 4px;

      span {
        outline: none;
        user-select: none;
      }
    }

    &--failed {
      color: $alizarin-crimson;
      display: inline;
      padding: 5px 8px 3px 8px;
      background: rgba(238, 36, 36, 0.1);
      border-radius: 4px;

      span {
        outline: none;
        user-select: none;
      }
    }

    &--failed-zero {
      color: #5a758a;
      display: inline;
      padding: 5px 8px 3px 8px;
      background: rgba(90, 117, 138, 0.1);
      border-radius: 4px;

      span {
        outline: none;
        user-select: none;
      }
    }

    &--duplicates {
      color: #5a758a;
      display: inline;
      padding: 5px 8px 3px 8px;
      background: rgba(90, 117, 138, 0.1);
      border-radius: 4px;

      span {
        outline: none;
        user-select: none;
      }
    }

    &--entity {
      margin-left: 10px;
    }

    .import-table__download {
      cursor: pointer;

      &--blocked {
        color: #99aeb9;
        cursor: not-allowed;
      }

      &--empty {
        cursor: initial;
      }
    }
  }

  .import-checkbox {
    width: 42px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 0;

    input {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}

.import-table__tr--gray-background {
  position: sticky;
  top: 0;
  background-color: #f6f8fa;
  display: table;
  width: 1038px;
  table-layout: fixed;
  border-bottom: 1px solid $botticelli;
  height: 51px;
  overflow-x: scroll;
  z-index: 10;
}

@media only screen and (min-width: 1400px) {
  .import-table,
  .import-table tbody,
  .import-table__tr--gray-background,
  .import-table .import-table__tr--no-data {
    width: 1247px;
  }
}

.resource-icon {
  background-repeat: no-repeat;
  width: 19px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.contact-icon {
  @extend .import-contact-icon;
  @extend .resource-icon;
}

.opportunity-icon {
  @extend .import-opportunity-icon;
  @extend .resource-icon;
}

.company-icon {
  @extend .import-company-icon;
  @extend .resource-icon;
}

.custom-icon {
  @extend .import-custom-icon;
  @extend .resource-icon;
}

.cursor-pointer {
  cursor: pointer;
}
