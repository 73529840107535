.import-entity {
  display: flex;
  position: relative;
  padding-left: 80px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom: 1px solid $botticelli;
  font-family: BentonSans Book;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  color: $smalt-blue;
  padding-top: 18px;
  padding-bottom: 13px;
  margin: 0;

  .import-entity__resource {
    padding-left: 10px;
    font-weight: normal;
    font-size: small;
  }
  &__title {
    max-width: 76%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .import-entity__radio-btn {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.import-entity__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid $botticelli;
  border-radius: 50%;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 30px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.import-entity {
  .import-entity__radio-btn:checked ~ .import-entity__checkmark:after {
    display: block;
  }

  .import-entity__checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $smalt-blue;
  }
}

