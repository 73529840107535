%icon {
  $icons-path: '../images/icons/';
}

$icons-path: '../images/icons';

@mixin round-icon-frame($icon-size) {
  width: $icon-size;
  height: $icon-size;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $icon-size;
}

.timeline-attachment-icon {
  background-image: url($icons-path + '/timeline/attachment.svg');
}

.timeline-note-icon {
  background-image: url($icons-path + '/timeline/note.svg');
}

.timeline-event-icon {
  background-image: url($icons-path + '/timeline/event.svg');
}

.timeline-left-voicemail-icon {
  background-image: url($icons-path + '/timeline/left-voicemail.svg');
}

.timeline-voicemail-icon {
  background-image: url($icons-path + '/timeline/voicemail.svg');
}

.timeline-successful-call-icon {
  background-image: url($icons-path + '/timeline/call.svg');
}

.timeline-didnt-connect-icon {
  background-image: url($icons-path + '/timeline/didnt-connect.svg');
}

.timeline-outbound-call-icon {
  background-image: url($icons-path + '/timeline/outbound-call.svg');
}

.timeline-inbound-call-icon {
  background-image: url($icons-path + '/timeline/inbound-call.svg');
}

.timeline-missed-call-icon {
  background-image: url($icons-path + '/timeline/missed-call.svg');
}

.timeline-forward-call-icon {
  background-image: url($icons-path + '/timeline/forward-call.svg');
}

.timeline-task-icon {
  background-image: url($icons-path + '/timeline/check.svg');
}

.timeline-outbound-email-icon {
  background-image: url($icons-path + '/timeline/outbound-email.svg');
}

.timeline-inbound-email-icon {
  background-image: url($icons-path + '/timeline/inbound-email.svg');
}

.timeline-outbound-sms-icon {
  background-image: url($icons-path + '/timeline/outbound-text.svg');
}

.timeline-text-icon {
  background-image: url($icons-path + '/timeline/text.svg');
}

.timeline-met-in-person-icon {
  background-image: url($icons-path + '/timeline/met-in-person.svg');
}

.timeline-reminder-icon {
  background-image: url($icons-path + '/timeline/reminder.svg');
}

// contact card icons

.campaign-v2-icon {
  background-image: url($icons-path + '/v2/campaign-icon.svg');
}

.call-v2-blue-icon {
  background-image: url($icons-path + '/v2/call-icon.svg');
}

.call-v2-icon-white {
  background-image: url($icons-path + '/v2/call-icon-white.svg');
}

.call-v2-color-icon {
  background-image: url($icons-path + '/v2/call-icon-color.svg');
}

.check-v2-icon {
  background-image: url($icons-path + '/v2/check.svg');
}

.chevron-left-v2 {
  background-image: url($icons-path + '/v2/chevron-left.svg');
}

.company-v2-icon {
  background-image: url($icons-path + '/v2/company-icon.svg');
}

.contact-v2-icon {
  background-image: url($icons-path + '/v2/contact-icon.svg');
}

.date-v2-icon {
  background-image: url($icons-path + '/v2/date-icon.svg');
}

.delete-v2-blue-icon {
  background-image: url($icons-path + '/v2/trash-can-blue-icon.svg');
}

.done-v2-icon {
  background-image: url($icons-path + '/v2/done-icon.svg');
}

.done-v2-blue-icon {
  background-image: url($icons-path + '/v2/done-blue-icon.svg');
}

.dropdown-v2-white-icon {
  background-image: url($icons-path + '/v2/drop-down.svg');
}

.dropdown-v2-casper-icon {
  background-image: url($icons-path + '/v2/dropdown-icon-casper.svg');
}

.dropdown-v2-icon {
  background-image: url($icons-path + '/v2/dropdown-icon.svg');
}

.dropdown-import-icon {
  background-image: url($icons-path + '/v2/dropdown-import.svg');
}

.custom-v2-icon {
  background-image: url($icons-path + '/v2/custom-icon.svg');
}

.import-v2-icon {
  background-image: url($icons-path + '/v2/import-icon.svg');
}

.import-v2-update-icon {
  background-image: url($icons-path + '/v2/import-update-icon.svg');
}

.import-v2-delete-icon {
  background-image: url($icons-path + '/v2/import-delete-icon.svg');
}

.import-v2-opportunity-icon {
  background-image: url($icons-path + '/v2/import-opportunity-icon.svg');
}

.import-v2-csv-icon {
  background-image: url($icons-path + '/v2/import-csv-icon.svg');
}

.import-v2-file-icon {
  background-image: url($icons-path + '/v2/import-file-icon.svg');
}

.import-v2-campaigns-icons {
  background-image: url($icons-path + '/v2/import-campaigns-icons.svg');
}

.email-v2-blue-icon {
  background-image: url($icons-path + '/v2/email-icon.svg');
}

.event-v2-icon {
  background-image: url($icons-path + '/v2/event-icon.svg');
}

.facebook-v2-icon {
  background-image: url($icons-path + '/v2/facebook-icon.svg');
}

.google-v2-icon {
  background-image: url($icons-path + '/v2/google-icon.svg');
}

.handshake-v2-icon {
  background-image: url($icons-path + '/v2/handshake-icon.svg');
}

.hang-up-v2-icon {
  background-image: url($icons-path + '/v2/hang-up-icon.svg');
}

.follow-up-v2-icon {
  background-image: url($icons-path + '/v2/follow-up-icon.svg');
}

.linkedin-v2-icon {
  background-image: url($icons-path + '/v2/linkedin-icon.svg');
}

.new-deal-alert-icon {
  background-image: url($icons-path + '/v2/new-deal-alert-icon.svg');
}

.opportunity-v2-icon {
  background-image: url($icons-path + '/v2/opportunity-icon.svg');
}

.pencil-icon {
  background-image: url($icons-path + '/v2/pencil-icon.svg');
}

.pencil-orange-icon {
  background-image: url($icons-path + '/v2/pencil-orange-icon.svg');
}

.pencil-icon-smalt-blue {
  background-image: url($icons-path + '/v2/pencil-icon-smalt-blue.svg');
}

.remove-v2-blue-icon {
  background-image: url($icons-path + '/v2/remove-icon.svg');
}

.remove-v2-salmon-icon {
  background-image: url($icons-path + '/v2/remove-salmon-icon.svg');
}

.reject-v2-icon {
  background-image: url($icons-path + '/v2/reject-icon.svg');
}

.remind-me-v2-blue-icon {
  background-image: url($icons-path + '/v2/reminders-blue-icon.svg');
}

.reminders-v2-color-icon {
  background-image: url($icons-path + '/v2/reminders-icon-color.svg');
}

.reports-v2-icon {
  background-image: url($icons-path + '/v2/reports-icon.svg');
}

.search-v2-icon {
  background-image: url($icons-path + '/v2/search-icon.svg');
}

.sms-v2-blue-icon {
  background-image: url($icons-path + '/v2/chat-icon.svg');
}

.spirito-v2-orange-icon {
  background-image: url($icons-path + '/v2/spirito-icon-orange.svg');
}

.three-dots-v2-icon {
  background-image: url($icons-path + '/v2/3-dot-icon.svg');
}

.twitter-v2-icon {
  background-image: url($icons-path + '/v2/twitter-icon.svg');
}

.bookings-v2-icon {
  background-image: url($icons-path + '/v2/bookings-icon.svg');
}

.pipeline-v2-icon {
  background-image: url($icons-path + '/v2/pipeline-icon.svg');
}

.quota-v2-icon {
  background-image: url($icons-path + '/v2/quota-icon.svg');
}

.win-v2-icon {
  background-image: url($icons-path + '/v2/win-icon.svg');
}

.calendar-events-icon {
  background-image: url($icons-path + '/v2/calendar-events-icon.svg');
}

.past-dues-icon {
  background-image: url($icons-path + '/v2/past-dues-icon.svg');
}

.reminders-open-icon {
  background-image: url($icons-path + '/v2/reminders-open-icon.svg');
}

.talk-time-icon {
  background-image: url($icons-path + '/v2/talk-time-icon.svg');
}

.call-connected-icon {
  background-image: url($icons-path + '/v2/call-connected-icon.svg');
}

.total-calls-icon {
  background-image: url($icons-path + '/v2/total-calls-icon.svg');
}

.trash-can-small-v2-blue-icon {
  background-image: url($icons-path + '/v2/trash-can-small.svg');
}

.trash-can-small-v2-orange-icon {
  background-image: url($icons-path + '/v2/trash-can-orange-small.svg');
}

.new-contacts-v2-icon {
  background-image: url($icons-path + '/v2/new-contacts-icon.svg');
}

/* Large icons with thin outlines, for actions */

.sms-icon {
  background-image: url($icons-path + '/sms-icon.svg');
}

.phone-grey-icon {
  background-image: url($icons-path + '/phone-grey.svg');
}

.calendar-orange-icon {
  background-image: url($icons-path + '/calendar-orange.svg');
}

.done-orange-icon {
  background-image: url($icons-path + '/ui/done-orange.svg');
}

.spiro-icon {
  background-image: url('../images/spiro-logo-grey.svg');
}

.envelope-orange-icon {
  background-image: url($icons-path + '/envelope-orange.svg');
}

.envelope-orange-light-icon {
  background-image: url($icons-path + '/envelope-orange-light.svg');
}

.coffee-orange-icon {
  background-image: url($icons-path + '/coffee-orange.svg');
}

.coffee-orange-light-icon {
  background-image: url($icons-path + '/coffee-orange-light.svg');
}

.question-mark-orange-icon {
  background-image: url($icons-path + '/question-mark-orange.svg');
}

.question-mark-orange-light-icon {
  background-image: url($icons-path + '/question-mark-orange-light.svg');
}

.plus-orange-icon {
  background-image: url($icons-path + '/plus-orange.svg');
}

.plus-orange-light-icon {
  background-image: url($icons-path + '/plus-orange-light.svg');
}

.import-orange-icon {
  background-image: url($icons-path + '/import-orange.svg');
}

.import-orange-light-icon {
  background-image: url($icons-path + '/import-orange-light.svg');
}

.tick-orange-icon {
  background-image: url($icons-path + '/tick-orange.svg');
}

.tick-orange-light-icon {
  background-image: url($icons-path + '/tick-orange-light.svg');
}

.unknown-person-orange-icon {
  background-image: url($icons-path + '/unknown-person-orange.svg');
}

.hand-point-infinity-orange-icon {
  background-image: url($icons-path + '/hand-point-infinity-orange.svg');
}

.hand-point-infinity-orange-light-icon {
  background-image: url($icons-path + '/hand-point-infinity-orange-light.svg');
}

.unknown-person-orange-icon {
  background-image: url($icons-path + '/unknown-person-orange.svg');
}

/* Smaller icons with thicker outlines, for UI elements */

.x-blue-ui-icon {
  background-image: url($icons-path + '/ui/x-blue-ui.svg');
}

.transcription-icon {
  background-image: url($icons-path + '/v2/activity/transcription-icon.svg');
}

.hourglass-transcription-icon {
  background-image: url($icons-path + '/v2/activity/sandbox-timer.svg');
}

.activity-spiro-icon {
  background-image: url($icons-path + '/v2/activity/spiro-icon.svg');
}

/* other */

.forward_call {
  background-image: url($icons-path + '/forward_call.svg');
}

.inbound_call {
  background-image: url($icons-path + '/inbound_call.svg');
}

.outbound_call {
  background-image: url($icons-path + '/outbound_call.svg');
}

.missed_call {
  background-image: url($icons-path + '/missed_call.svg');
}

.voicemail {
  background-image: url($icons-path + '/voicemail.svg');
}

// sidebar

.import-opportunity-icon {
  background-image: url($icons-path + '/opportunity-import.svg');
}

.import-contact-icon {
  background-image: url($icons-path + '/contact-import.svg');
}

.import-custom-icon {
  background-image: url($icons-path + '/custom-import.svg');
}

.import-company-icon {
  background-image: url($icons-path + '/company-import.svg');
}

.import-history-arrow-icon {
  background-image: url($icons-path + '/arrow-down-import-history.svg');
}
