.message-thread__item {
  position: relative;
  clear: both;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 300px;
  width: 100%;
  padding: 10px;
  overflow-wrap: break-word;
  background-color: $aqua-haze2;
  color: $dove-gray;
  font-family: 'BentonSans Book';
  font-size: 13px;

  a {
    color: $smalt-blue !important;
    text-decoration: none !important;
    margin-bottom: 10px;
    display: inline-block;
  }
}

.message-thread__item--outbound {
  float: left;

  .message-thread__item-arrow {
    left: -15px;
    border-left: 15px solid transparent;
    border-bottom: 15px solid $aqua-haze2;
  }

  p:nth-of-type(3) {
    float: right;
  }
}

.message-thread__item--inbound {
  float: right;

  .message-thread__item-arrow {
    right: -15px;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $aqua-haze2;
  }

  p:nth-of-type(2) {
    float: right;
  }
}

.message-thread__item--work {
  background: #689eab;
  color: white;

  .message-thread__item-arrow {
    border-bottom-color: #689eab;
  }

  .message-thread__item-subject {
    color: white;
  }
}

.message-thread__item--home {
  background: #309BFF;
  color: white;

  .message-thread__item-arrow {
    border-bottom-color: #309BFF;
  }

  .message-thread__item-subject {
    color: white;
  }
}

.message-thread__item-arrow {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
}

.message-thread__item-subject {
  font-family: 'BentonSans Medium';
  color: $smalt-blue;
  margin-bottom: 10px;
}

.message-thread__item-image {
  width: 100%;
  margin: 10px 0px;
}

.message-thread__item-date {
  float: right;
  margin-bottom: 0px;
}
