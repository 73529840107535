.spiro-dropdown-list-item {
  font-size: 13px;
  font-family: 'BentonSans Regular';
  background-color: $white;
  color: $smalt-blue;
  cursor: pointer;
  padding: 5px 40px 5px 20px;
  height: 25px;
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $solitude;
    color: $smalt-blue;
  }
}

.spiro-dropdown-list {
  pointer-events: auto;
  width: 100%;
  font-size: 11px;
  background-color: $white;
  color: $smalt-blue;
  max-height: 0;
  overflow: hidden;
  margin-top: 30px;
  &.open {
    max-height: 100vh;
    border-top: 1px solid $iron;
  }
}

.spiro-dropdown-list-body {
  overflow: hidden auto;
  height: calc(100% - 30px);

  &.extended {
    height: 100%;
  }
}

.spiro-dropdown-list-footer {
  padding: 5px;
  width: 100%;
  background-color: $aquaHaze;
  margin-top: auto;
  font-family: 'BentonSans Regular';
  font-size: 12px;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
  }
  &:focus {
    outline: none;
  }
}
