.import-upload-screen-msg {
  text-align: center;
  margin: 30px 15px 0px 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
}

.import-upload-screen-tip {
  padding: 35px 0px;

  .import-upload-screen-tip__header {
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 10px;
  }

  .import-upload-screen-tip__content {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.54);
    text-align: justify;
  }

  .import-upload-screen-tip__link {
    color: $primary-orange;
  }
}

.import-upload-screen__select {
  width: 100%;
  margin: 0 auto 24px auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.161);
  border-radius: 4px;
  position: relative;

  .loader-bouncing-dots {
    position: absolute;
    top: 10px;
    right: 30px;
  }
}

.import-upload-screen-main {
  max-width: 768px;
  margin: 0 auto;

  .import-upload-screen__centered {
    display: flex;
    justify-content: center;

    &.single {
      justify-content: center;
    }
  }

  .import-upload-screen-box {
    &.import-upload-screen-box-1 {
      display: flex;
      align-items: center;
    }

    &.import-upload-screen-box-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .import-v2-csv-icon {
    width: 160px;
    height: 120px;
    position: relative;
    right: 7px;
    padding-right: 40px;
    margin-left: 20px;
  }

  .import-upload-screen-box__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-width: 200px;

    .import-upload-screen__filename {
      display: block;
      width: 200px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .import-upload-screen__percentage {
        padding-left: 10px;
      }
    }

    .import-upload-screen__error-text {
      color: #dc3545;
    }
  }
}

.import-resource-selector {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .loader-bouncing-dots {
    top: 3px;
    right: 15px;
  }
}

.import-upload-screen-wrapper {
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 8px;
  height: 100%;

  .import-upload-screen-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .import-upload-screen-footer {
    display: flex;
    justify-content: flex-end;
  }
}

.import-custom-file-upload {
  border-radius: 4px;
  background-color: $light-purple;
  color: white;
  padding: 10px 16px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1rem;
  line-height: 100%;
  margin-top: 16px;

  input[type='file'] {
    display: none;
  }

  &.import-custom-file-upload--disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.import-upload-screen-footer {
  display: flex;
  justify-content: flex-end;
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 236px;
  right: 0;
  z-index: 1;
  border-top: 1px solid $mercury;
  padding: 30px 24px;

  button:first-child {
    margin-right: 16px;
  }
}

.import-download-field {
  display: flex;
  width: 100%;
  padding-top: 48px;
  align-items: center;
  text-align: center;
  justify-content: center;

  .import-download-field__button {
    display: inline;
    font-weight: 500;
    color: $primary-orange;
    cursor: pointer;
  }
}

.import-upload-screen-attachment {
  border-bottom: 1px solid rgba(128, 128, 128, 0.24);
  display: flex;
  align-items: center;
  padding-top: 30px;

  .file-icon {
    color: $light-purple;
  }

  .import-upload-screen-attachment-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px;
    flex: 1;
  }
}

.import-upload-screen-selector {
  margin-top: 20px;
}

.error-icon {
  color: #e31b0c;
}

.import-mapping-screen-container {
  padding-bottom: 150px;
}
