.import-confirmation-screen-box {
  margin: 0 auto;
  padding: 22px 0;
  width: 768px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.161);

  a {
    color: $eastern-blue;
  }

  .import-confirmation-screen-box__icon {
    @extend .check-v2-icon;
    margin-bottom: 22px;
    display: inline-block;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    color: $white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: $eastern-blue;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center;
  }
  .import-confirmation-screen-box__title {
    padding-bottom: 22px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #53758c;
  }
  .import-confirmation-screen-box__content {
    white-space: pre-line;
    padding-bottom: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #53758c;
  }
  .import-confirmation-screen-box__link {
    padding-bottom: 22px;
  }
}

.confirmation-heading {
  padding: 32px 0px 30px 0px;
}

.circle-outline-icon {
  color: $fruit-salad;
}

.confirmation-footer {
  display: flex;
  justify-content: flex-end;
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 236px;
  right: 0;
  z-index: 1;
  border-top: 1px solid $mercury;
  padding: 30px 24px;
}
